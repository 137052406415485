/** @jsx jsx */
import React, { useState, useEffect } from "react"
import Particles from "react-particles-js"
import { Box, Text, jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

interface Quote {
  text: string
  author: string
}

const quotes: Quote[] = [
  {
    text:
      "To succeed in your mission, you must have single-minded devotion to your goal",
    author: "Dr. A.P.J Abdul Kalam",
  },
  {
    text:
      "Education is the manifestation of perfection present already in man. Divinity is the manifestation of the religion already in man.",
    author: "Swami Vivekananda",
  },
  {
    text:
      "The Highest Education is that which does not merely give us information but makes our life in harmony with all existence",
    author: "Rabindranath Tagore",
  },
  {
    text:
      "The function of education is to teach one to think intensively and to think critically. Intelligence plus character – that is the goal of true education",
    author: "Martin Luther King",
  },
  {
    text:
      "Happiness is when what you think, what you say, and what you do are in harmony.",
    author: "Mahatma Gandhi",
  },
  {
    text: "One Child, One Teacher, One Pen and One Book can change the world",
    author: "Malala Yousafzai",
  },
  {
    text:
      "Educationists should build the capacities of the spirit of inquiry, creativity, entrepreneurial and moral leadership among students and become their role model",
    author: "Dr. A.P.J Abdul Kalam",
  },
  {
    text:
      "Education is not the learning of facts, but the training of the mind to think",
    author: "Albert Einstein",
  },
]

const QuoteSection = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "headerBg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(index => (index + 1) % quotes.length)
    }, 7000)
    return () => clearInterval(interval)
  }, [])

  return (
    <BackgroundImage Tag="section" fluid={data.bgImage.childImageSharp.fluid}>
      <Box
        sx={{ position: "relative" }}
        color="white"
        px={3}
        py={2}
        sx={{
          background: "rgba(0, 0, 0, 0.7)",
        }}
      >
        <Box py={6}>
          {/* Black overlay 
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              background: "rgba(0, 0, 0, 0.7)",
            }}
          />
          Desktop particle
          <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              display: ["none", null, "block"],
            }}
          >
            <Particles
              params={{
                particles: {
                  number: {
                    value: 70,
                  },
                  size: {
                    value: 3,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "repulse",
                    },
                  },
                },
              }}
            />
          </Box>
          {/* Mobile particle */}
          {/* <Box
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              display: ["block", null, "none"],
            }}
          >
            <Particles
              params={{
                particles: {
                  number: {
                    value: 10,
                  },
                  size: {
                    value: 3,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "repulse",
                    },
                  },
                },
              }}
            />
          </Box> */}
          {quotes.map((quote, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "100%",
                maxWidth: "1000px",
                transform: "translate(-50%, -50%)",
                transition: "opacity 1s",
                textAlign: "center",
                ...(index === activeIndex
                  ? {
                      pointerEvents: "auto",
                      opacity: 1,
                    }
                  : {
                      pointerEvents: "none",
                      opacity: 0,
                    }),
              }}
              p={3}
            >
              <Text mb={2} sx={{ fontSize: [3, null, 4] }} color="white">
                {quote.text}
              </Text>
              <Text sx={{ fontSize: [1, null, 2] }} color="white">
                - {quote.author}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </BackgroundImage>
  )
}

export default QuoteSection
