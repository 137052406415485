import React from "react"
import Layout from "../../components/Layout"
import { Box, Grid } from "theme-ui"
import { graphql } from "gatsby"
import SEO from "../../components/SEO"
import ContentSection from "../../components/ContentSection"
import CourseCard from "../../components/Course/CourseCard"
import QuoteSection from "../../components/QuoteSection"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const CoursePage = ({ data }) => (
  <Box>
    <SEO title="Courses" />
    <Layout>
      <QuoteSection />
      <ContentSection
        header="COURSES OFFERED"
        noHeaderBorder={true}
        body={
          <Grid
            sx={{ justifyItems: "center", maxWidth: "1000px", margin: "auto" }}
            columns={[1, 1, 1,3,3]}
            gap={5}
          >
            <Box data-sal="slide-right">
              <CourseCard
                image="https://st.adda247.com/https://www.careerpower.in/blog/wp-content/uploads/2023/12/07112334/UPSC.png"
                header="The UPSC Course"
                body="
          These are pathways programmes designed for fresh aspirants of the Civil Services Exam. These preparation courses ...
          "
                href="/course/upsc"
                mobileNumber1="9962600039"
                mobileNumber2="9962600024"
              />
            </Box>
            <Box data-sal="slide-left">
              <CourseCard
                image="https://media.getmyuni.com/assets/images/articles/tnpsc-one-time-registration.jpg"
                header="THE TNSPC Course"
                body="
              These are pathways programmes designed for fresh aspirants of the Civil Services Exam. These preparation courses ....
          "
                href="/course/tnpsc"
                mobileNumber1="9962600037"
                mobileNumber2="9962600038"
              />
            </Box>
            <Box data-sal="slide-right">
              <CourseCard
                image="https://img.jagranjosh.com/images/2022/July/672022/sscccimage(1).jpg"
                header="SSC Course"
                body="
              These are pathways programmes designed for fresh aspirants of the Civil Services Exam. These preparation courses ...  
          "
                href="/course/ssc"
                mobileNumber1="9962600043"
                mobileNumber2="9962600052"
              />
            </Box>
           
          </Grid>
        }
      />
    </Layout>
  </Box>
)

export default CoursePage
