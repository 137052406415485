/** @jsx jsx */
import React, { useState } from "react"
import { Card, Heading, Text, Button, jsx,Image, Flex, Box } from "theme-ui"
import { Link } from "gatsby"
import { IoCall } from "react-icons/io5"

interface CourseCardProps {
  header: string
  body: string
  href: string
  image: string
  mobileNumber1: string
  mobileNumber2:string
}

const CourseCard = (props: CourseCardProps): JSX.Element => {
   const [isHovered, setIsHovered] = useState(false)
  const { header, body, href, image, mobileNumber1, mobileNumber2 } = props
  
  return (
    <Link to={href} style={{ textDecoration: "none" }}>
      <Card
        bg="white"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          textAlign: "justify",
          borderRadius: "25px",
          cursor: "pointer",
          boxShadow: isHovered ? "0 0 10px rgba(0, 0, 0, 0.3)" : "",
          maxWidth: "450px",
        }}
      >
        <Image
          sx={{
            width: "100%",
            height: "175px",
            marginBottom: "20px",
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
          src={image}
        />
        <Box px={30}>
          <Heading sx={{ fontSize: "16px" }} color="black" variant="caps">
            {header}
          </Heading>
          <Text variant="secondary" sx={{ fontSize: "14px" }} mt={3}>
            {body}
          </Text>
          <Flex py={20} sx={{ gap: "14px" }}>
            <IoCall color={"black"} size={14} />
            <Flex sx={{ fontWeight: "700", fontSize: "14px" }}>
              <Text
                onClick={e => {
                  e.preventDefault()
                  const number1 = `tel:${mobileNumber1}`
                  window.location.href = number1
                }}
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                color={"var(--theme-ui-colors-primary,#BE0800)"}
              >
                {mobileNumber1}
                {","}
              </Text>
              <Text
                onClick={e => {
                  e.preventDefault()
                  const number2 = `tel:${mobileNumber2}`
                  window.location.href = number2
                }}
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                ml={"10px"}
                color={"var(--theme-ui-colors-primary,#BE0800)"}
              >
                {mobileNumber2}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Card>
    </Link>
  )
}

export default CourseCard
